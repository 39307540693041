import React from 'react';
import { useSelector } from 'react-redux';

import { selectIsFeatureDiscoveryVisible } from '@selectors/favorites';
import { selectIsAddToBasketToolTipVisible } from '@selectors/basket';

import Hamburger from '@common/components/Hamburger';
import { useMainMenu } from '../Navbar.helpers';
import LayoutOverlay from '@common/containers/LayoutOverlay/LayoutOverlay';

import { BasketLink, FavoritesLink, VipLink } from './Links';
import Logo from './Logo';
import { HeaderStyled, Content, RightSide } from './Header.styled';

const Header = () => {
  const isFavoritesPopperVisible = useSelector(selectIsFeatureDiscoveryVisible);
  const isAddToBasketPopperVisible = useSelector(selectIsAddToBasketToolTipVisible);

  const { isOpen, toggle, popper } = useMainMenu();

  return (
    <HeaderStyled
      onClick={popper.close}
      isVisible={isOpen || isFavoritesPopperVisible || isAddToBasketPopperVisible}
    >
      <LayoutOverlay disableContent />
      <Content>
        <Logo />

        <RightSide>
          <BasketLink />

          <FavoritesLink />

          <VipLink />

          <Hamburger
            isToggled={isOpen}
            onClick={toggle}
          />
        </RightSide>
      </Content>
    </HeaderStyled>
  );
};

export default Header;
