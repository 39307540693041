import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';

import { closeAddToBasketTooltip } from '@actions/basket';

import { getRouteUrl } from '@utils/urls';

import { BASKET } from '@common/routes';
import { Close as CloseIcon } from '@common/components/Icons';
import { PopperArrow } from '../NavPopper.styled';

import {
  AddToBasketPopperWrapper,
  AddToBasketPopperTitle,
  AddToBasketPopperButton,
} from './AddToTheBasketPopper.styled';

const AddToBasketPopper = ({
  setElement, styles, attributes, setArrowElement, arrowStyles,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dispatch = useDispatch();

  const handleClosePopper = () => {
    dispatch(closeAddToBasketTooltip());
  };

  useEffect(() => {
    const timeoutId = setTimeout(handleClosePopper, 5000);

    return () => {
      handleClosePopper();
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <AddToBasketPopperWrapper
      data-testid="add-to-basket-popper"
      ref={setElement}
      style={styles}
      {...attributes}
    >
      <Link
        legacyBehavior
        href={BASKET}
        as={getRouteUrl(language, BASKET)}
        passHref
      >
        <AddToBasketPopperTitle>
          {t('shop.detail.info.addedtobasket.text', 'In den Warenkorb gelegt')}
        </AddToBasketPopperTitle>
      </Link>
      <AddToBasketPopperButton
        type="button"
        onClick={handleClosePopper}
      >
        <CloseIcon />
      </AddToBasketPopperButton>
      <PopperArrow
        ref={setArrowElement}
        shouldOffset
        style={arrowStyles}
      />
    </AddToBasketPopperWrapper>
  );
};

AddToBasketPopper.propTypes = {
  setElement: PropTypes.func.isRequired,
  setArrowElement: PropTypes.func.isRequired,
  styles: PropTypes.shape({}).isRequired,
  attributes: PropTypes.shape({}),
  arrowStyles: PropTypes.shape({}),
};

AddToBasketPopper.defaultProps = {
  attributes: {},
  arrowStyles: {},
};

export default AddToBasketPopper;
