import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import { selectIsFeatureDiscoveryVisible } from '@selectors/favorites';

import { getRouteUrl } from '@utils/urls';

import { FAVORITES } from '@common/routes';
import { HeartOutline as HeartOutlineIcon } from '@common/components/Icons';
import { useMainMenu } from '../../Navbar.helpers';

import { IconLinkWrapper } from './common.styled';

const FavoritesLink = () => {
  const { locale } = useRouter();
  const { popper } = useMainMenu();

  const isFavoritesPopperVisible = useSelector(selectIsFeatureDiscoveryVisible);

  return (
    <IconLinkWrapper
      ref={element => {
        if (isFavoritesPopperVisible) {
          popper.setReferenceElement(element);
        }
      }}
    >
      <Link
        legacyBehavior
        href={FAVORITES}
        as={getRouteUrl(locale, FAVORITES)}
      >
        <a>
          <HeartOutlineIcon fill="white" />
        </a>
      </Link>
    </IconLinkWrapper>
  );
};

export default FavoritesLink;
