import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

import { UrlType } from '@common/types/link';

import { ImageLinkStyled, ImageLinkText, LazyImage } from './ImageLink.styled';

const ImageLink = React.forwardRef(
  ({
    children, linkAs, linkHref, image, onClick, isFullWidth, isButton,
  }, ref) => {
    const imageBox = (
      <ImageLinkStyled
        ref={ref}
        as={isButton ? 'button' : 'a'}
        onClick={onClick}
        isFullWidth={isFullWidth}
      >
        <LazyImage
          alt={children}
          src={image}
          breakpoints={[1600, 1200, 640, 480, 0]}
          aspectRatios="4:1"
        />
        <ImageLinkText>{children}</ImageLinkText>
      </ImageLinkStyled>
    );

    return (
      <React.Fragment>
        {isButton ? (
          imageBox
        ) : (
          <Link
            legacyBehavior
            as={linkAs}
            href={linkHref}
            locale={false}
            passHref
          >
            {imageBox}
          </Link>
        )}
      </React.Fragment>
    );
  }
);

ImageLink.propTypes = {
  children: PropTypes.node.isRequired,
  linkAs: UrlType,
  linkHref: UrlType,
  image: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isFullWidth: PropTypes.bool,
  isButton: PropTypes.bool,
};

ImageLink.defaultProps = {
  linkAs: undefined,
  linkHref: undefined,
  onClick: undefined,
  isFullWidth: false,
  isButton: false,
};

export default ImageLink;
