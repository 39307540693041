import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { getRouteUrl } from '@utils/urls';

import { PRODUCTS_CATEGORY } from '@common/routes';
import { categoryShape } from '@common/types/category';
import { AnimationItem } from '@common/components/AnimationQueue';
import Columns from '../Columns';
import { MenuLink } from '../Menu.styled';

import CategoryColumnItem from './CategoryColumnItem';
import {
  BackButton,
  BackChevronIcon,
  ExploreAllChevronIcon,
  MenuTitle,
} from './CategorySubmenu.styled';

const Category = ({ category, closeSubmenu }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <Fragment>
      <AnimationItem
        Component={BackButton}
        onClick={closeSubmenu}
      >
        <BackChevronIcon $left />
        <MenuTitle>{category.title}</MenuTitle>
      </AnimationItem>

      <Link
        legacyBehavior
        as={getRouteUrl(language, PRODUCTS_CATEGORY, {
          id: category.categoryId,
          slug: category.slug,
        })}
        href={{
          pathname: PRODUCTS_CATEGORY,
          query: {
            id: category.categoryId,
            slug: category.slug,
          },
        }}
        passHref
      >
        <AnimationItem Component={MenuLink}>
          {t('categorySub.exploreAll', 'Alles entdecken')}
          <ExploreAllChevronIcon />
        </AnimationItem>
      </Link>

      <Columns
        items={category.children}
        itemId="categoryId"
        ItemComponent={CategoryColumnItem}
      />
    </Fragment>
  );
};

Category.propTypes = {
  category: categoryShape.isRequired,
  closeSubmenu: PropTypes.func.isRequired,
};

export default Category;
