import styled, { keyframes } from 'styled-components';

import { getThemeColor } from '@utils/styled';

export const backgroundTransition = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const PopperArrow = styled.div`
  position: absolute;
  top: -8px;
  z-index: 101;
  width: 16px;
  height: 16px;

  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: ${({ shouldOffset }) => (shouldOffset ? '-1.2rem' : 0)};
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-top: none;
    border-bottom-color: ${getThemeColor('grey.100')};
  }
`;
